
import Hero from "@/components/elements/Hero.vue";
import Header from "@/components/elements/Header.vue";
import FanzoneTitle from "@/components/title/FanzoneTitle.vue";
import Stream from "@/components/elements/Stream.vue";
import Subtitle from "@/components/elements/Subtitle.vue"
import {Options, Vue} from "vue-class-component";
import Settings from "@/services/Settings";

@Options({
  components: {
    Stream,
    FanzoneTitle,
    Header,
    Hero,
    Subtitle
  },
})
export default class Fanzone extends Vue {
  private gameId: string | null = null;
  private projectId: string | null = null;
  private host: string | null = null;
  private loader = false;
  private loaderFeedData = false;
  private game: any = {};
  private page = 1;
  private totalPages = 1;
  private limit = 12;
  private total = 0;
  private feedData = [];
  private keywords = [];

  loadData() {
    if (this.loader) return;
    this.loader = true;
    this.$http.get(this.host + '/public/games/' + this.gameId, {
      params: {}
    }).then((response) => {
      this.game = response.data;
      this.projectId = response.data.projectStreamId;
      this.keywords = response.data.keywords;
      this.loadFeedData();

      this.loader = false;
    }, () => {
      this.loader = false;
    });
  }

  loadFeedData() {
    if (this.loaderFeedData) return;
    this.loaderFeedData = true;
    this.$http.get(this.host + '/public/results/' + this.projectId +'?sort_by=time%20desc&page='+ this.page +'&limit=' + this.limit, {
      params: {}
    }).then((response) => {
      this.feedData = [
        ...this.feedData,
        ...response.data.inline.resources
      ] as never[];
      this.total = response.data.total;
      this.loaderFeedData = false;
      this.totalPages = response.data.pages;
    }, () => {
      this.loaderFeedData = false;
    });
  }

  paginateData() {
    this.page+=1;
    this.loadFeedData();
  }

  mounted() {
    this.gameId = this.$route.params.gameId as string;
    const settings = new Settings();
    this.host = settings.getSetting('apiHost');
    this.loadData();
  }
}

